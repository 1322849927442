import React from "react";
import {DocumentInfo} from "../../types/document";
import {AppBar, Chip, Stack, Toolbar, Typography} from "@mui/material";
import {isPreview} from "../../libs/isPreview";

export const VideoViewerHeader: React.FC<{info: DocumentInfo}> = ({info}) => {
  return (
    <AppBar
      position="static"
      color="transparent"
      elevation={0}
      sx={{
        borderBottom: '1px solid white',
      }}
    >
      <Toolbar>
        <Stack direction="row" sx={{flexGrow: 1}} spacing={2} minWidth={0}>
          <Typography variant="h6" noWrap>
            {info.content.name}
          </Typography>
          {isPreview() && <Chip label="プレビュー" color="info" variant="outlined" />}
        </Stack>
      </Toolbar>
    </AppBar>
  )
}
