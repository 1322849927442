import React, {useEffect, useRef} from "react";
import {DocumentInfo} from "../../types/document";
import {Box} from "@mui/material";
import {queue} from "../../libs/queue";
import {isPreview} from "../../libs/isPreview";
import {useVideoAppointmentPopup} from "../../hooks/useVideoAppointmentPopup";
import {useViewerSession} from "../../hooks/useViewerSession";
import {timestamps} from "../../libs/timestamps";

const log = {
  recording: false,
  token: '',
  session_id: 0,
  type: 'video',
  at: '',
  start: 0,
  end: 0,
}

/**
 * 動画プレイヤー
 * @param info
 * @constructor
 */
export const VideoViewerPlayer: React.FC<{info: DocumentInfo}> = ({info}) => {
  const {setSessionId} = useViewerSession(info.token);
  const videoEl = useRef<HTMLVideoElement>();
  const {setShow} = useVideoAppointmentPopup();

  useEffect(() => {
    const video = videoEl.current;
    if (! video) return;

    log.token = info.token;
    log.session_id = info.session ?? 0;

    //記録開始
    const startRecording = () => {
      // 記録中ではなく、シーク中でもなく、再生中なら、再生範囲の開始を更新する
      if (! log.recording && ! video.seeking && ! video.paused) {
        log.start = log.end = video.currentTime;
        log.at = timestamps.getRemoteNow().setZone('UTC').toISO();
        log.recording = true;
      }

      //セッションの有効期限を延ばす
      info.session && setSessionId(info.session);
    }

    //記録終了
    const endRecording = () => {
      // 記録中なら再生範囲をログに記録する
      if (log.recording) {
        // シーク中で無ければ現在時点を終了時間とする
        if (! video.seeking) {
          log.end = video.currentTime;
        }
        // 開始時間と終了時間の差分が0.1秒以下だった場合は記録しない
        // （再生中にシークした場合に、再生時間が0秒のログができてしまうことへの対策）
        if (! isPreview() && (log.end - log.start) > 0.1) {
          queue.post('', log).then();
        }
        // 計測を終了する
        log.recording = false;
      }

      //セッションの有効期限を延ばす
      info.session && setSessionId(info.session);
    }

    //画面アンロード
    const handleUnload = () => {
      if (isPreview() || video.paused) {
        return;
      }
      log.end = video.currentTime;
      const backendUrl = process.env.REACT_APP_BACKEND_APP_URL;
      const sessionId = info.session;
      const formData = new FormData();
      Object.entries(log).forEach(([key, value]) => {
        formData.append(key, String(value));
      });
      navigator.sendBeacon(
        `${backendUrl}/viewer-api/sessions/${sessionId}/track_video_view`,
        formData,
      );
    }
    //再生時間更新イベントハンドラ
    const handleTimeUpdate = () => {
      //アポ打診ポップアップ表示判定
      if (info.appointment_popup && video.currentTime > (info.appointment_popup.duration ?? 0)) {
        setShow().then();
      }
      // 記録中であり、シーク中ではなく、再生中なら、再生範囲の終端を更新する
      if (log.recording &&
        ! video.seeking &&
        ! video.paused &&
        video.currentTime > log.start
      ) {
        log.end = video.currentTime;
      }
    }

    //イベントのバインド
    ['playing', 'play', 'seeked'].forEach(e => video.addEventListener(e, startRecording));
    ['pause', 'ended', 'seeking'].forEach(e => video.addEventListener(e, endRecording));
    video.addEventListener('timeupdate', handleTimeUpdate);
    window.addEventListener('unload', handleUnload);

    return () => {
      ['playing', 'play', 'seeked'].forEach(e => video.removeEventListener(e, startRecording));
      ['pause', 'ended', 'seeking'].forEach(e => video.removeEventListener(e, endRecording));
      video.removeEventListener('timeupdate', handleTimeUpdate);
      window.removeEventListener('unload', handleUnload);
    }
  }, [info.appointment_popup, info.session, info.token, setSessionId, setShow]);

  return (
    <Box
      sx={{
        height: 'calc(100vh - 64px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#cccccc',
      }}
    >
      <Box
        component="video"
        src={info.content.file_url}
        controls
        disablePictureInPicture
        controlsList="nodownload"
        sx={{
          maxHeight: '80%',
          maxWidth: '100%',
        }}
        ref={videoEl}
      />
    </Box>
  )
}
