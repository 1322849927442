import React from "react";
import {DocumentInfo} from "../../types/document";
import {IconButton} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import printJS from "print-js";

/**
 * PDF印刷ボタン
 * @param info
 * @constructor
 */
export const PdfViewerPrint: React.FC<{info: DocumentInfo}> = ({info}) => {
  const handleClick = () => {
    printJS({
        printable: `${process.env.REACT_APP_IMG_URL}/pdf/${info.content.physical_name}/${info.content.physical_name}.pdf`,
        type: 'pdf',
        showModal: true,
    });
  }

  return (
    <IconButton
      size="large"
      color="inherit"
      onClick={handleClick}
      edge="end"
    >
      <PrintIcon />
    </IconButton>
  )
}
