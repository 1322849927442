import React, {useState} from "react";
import {DocumentInfo} from "../../types/document";
import {Dialog, DialogTitle, IconButton, ImageList, ImageListItem, ImageListItemBar} from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";
import {baseTheme} from "../../themes/baseTheme";
import {getImagePath} from "../../libs/getImagePath";
import {useCurrentPage} from "../../hooks/useCurrentPage";

/**
 * ページ移動ダイアログ
 * @param info
 * @constructor
 */
export const PdfViewerImageListDialog: React.FC<{info: DocumentInfo}> = ({info}) => {
  const [open, setOpen] = useState(false);
  const [mounted, setMounted] = useState(false);
  const handleClickButton = () => {
    setOpen(true);
    setMounted(true);
  }
  const handleCloseDialog = () => setOpen(false);

  const {changePage} = useCurrentPage(info);

  const items = [...Array(info.content.page_count)].map((_,i) => {
    const page = i + 1;
    const title = `${page} ページ`;
    const handleClickPage = () => {
      changePage(page);
      setOpen(false);
    }
    return (
      <ImageListItem
        key={title}
        onClick={handleClickPage}
        sx={{
          cursor: 'pointer',
        }}
      >
        <img
          src={getImagePath(info, page)}
          alt={title}
        />
        <ImageListItemBar
          position="below"
          title={page}
          sx={{
            color: baseTheme.palette.text.secondary,
            textAlign: 'center',
          }}
        />
      </ImageListItem>
    );
  });

  return (
    <>
      {/* ボタンアイコン */}
      <IconButton
        size="large"
        color="inherit"
        onClick={handleClickButton}
        edge="end"
      >
        <AppsIcon />
      </IconButton>
      {/* ダイアログ */}
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        keepMounted={mounted}
      >
        <DialogTitle
          color={baseTheme.palette.text.secondary}
        >
          ページ移動
        </DialogTitle>
        <ImageList cols={3}>
          {items}
        </ImageList>
      </Dialog>
    </>
  )
}
