import React, {useState} from "react";
import {DocumentInfo} from "../../types/document";
import {AppointmentPopup} from "../AppointmentPopup/AppointmentPopup";
import {useVideoAppointmentPopup} from "../../hooks/useVideoAppointmentPopup";

/**
 * 動画ビューアのアポ打診ポップアップ表示判定
 * @param info
 * @constructor
 */
export const VideoViewerAppointmentPopup: React.FC<{info: DocumentInfo}> = ({info}) => {
  const [open, setOpen] = useState(false);
  const [opened, setOpened] = useState(false);
  const {isShow} = useVideoAppointmentPopup();

  //アポ打診ポップアップ表示判定
  if (! opened && isShow) {
    setOpen(true);
    setOpened(true);
  }

  const handleClosePopup = () => setOpen(false);

  return info.appointment_popup ? (
    <AppointmentPopup info={info} open={open} onClose={handleClosePopup} />
  ) : <></>;
}
