import React from "react";
import {LoadingAnimation} from "./LoadingAnimation";
import {baseTheme} from "../themes/baseTheme";
import {ThemeProvider} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import {useDocumentInfo} from "../hooks/useDocumentInfo";
import {DocumentTitle} from "./DocumentTitle";
import {PdfViewer} from "./PdfViewer/PdfViewer";
import {NotFound} from "./NotFound";
import {VideoViewer} from "./VideoViewer/VideoViewer";
import {TrackingPermissionPopup} from "./TrackingPermissionPopup";
import {videoTheme} from "../themes/videoTheme";
import {LoginForm} from "./LoginForm";

/**
 * ビューアの最上位コンポーネント
 * ドキュメント情報の取得などを行う
 *
 * @constructor
 */
export const Viewer: React.FC = () => {
  const token = Array.from(new URL(document.location.href).searchParams.keys())[0] ?? '';

  const {
    info,
    loading,
    isNotFound,
    isUnauthorized,
    loginFormTitle,
    loginFormMessage,
    needParameters,
    policyUrl,
  } = useDocumentInfo(token);

  const isPdf = info && info.content.type === 'pdf';
  const isVideo = info && info.content.type === 'video';

  return (
    <ThemeProvider theme={isVideo ? videoTheme : baseTheme}>
      <CssBaseline />
      {/* 読み込み中アニメーション */}
      {loading && <LoadingAnimation />}
      {/* ドキュメントタイトル */}
      {info && <DocumentTitle info={info} />}
      {/* PDFビューア */}
      {! loading && isPdf && <PdfViewer info={info} />}
      {/* 動画ビューア */}
      {! loading && isVideo && <VideoViewer info={info} />}
      {/* 解析同意ポップアップ */}
      {info && <TrackingPermissionPopup info={info} />}
      {/* not found */}
      {isNotFound && <NotFound />}
      {/* ログインフォーム */}
      {isUnauthorized && (
        <LoginForm
          token={token}
          title={loginFormTitle}
          message={loginFormMessage}
          needParameters={needParameters}
          policyUrl={policyUrl}
        />
      )}
    </ThemeProvider>
  )
}
