import React, {useState} from "react";
import {DocumentInfo} from "../types/document";
import {Box, Button, Card, CircularProgress, Slide} from "@mui/material";
import {baseTheme} from "../themes/baseTheme";
import {api} from "../libs/api";
import {isPreview} from "../libs/isPreview";

/**
 * 解析同意ポップアップ
 * @param info
 * @constructor
 */
export const TrackingPermissionPopup: React.FC<{info: DocumentInfo}> = ({info}) => {
  const [loading, setLoading] = useState(false);
  const [accepted, setAccepted] = useState(info.accepted_tracking);

  //表示テキストの取得
  const defaultText = 'サービス改善/営業活動の改善を目的として、閲覧の情報を取得する場合がございます。';
  const getDisplayText = (displayText: string) => {
    if (displayText === '') {
      return defaultText;
    }
    return displayText ?? defaultText;
  }
  const text = getDisplayText(info.tracking_permission.display_text);

  const handleClick = async () => {
    setLoading(true);
    if (! isPreview()) {
      await api.post(
        `/sessions/${info.session}/accept_tracking`,
      ).finally(() => setLoading(false));
    }
    setAccepted(true);
  }

  return (
    <Slide direction="up" in={! accepted}>
      <Card
        square={true}
        sx={{
          position: 'fixed',
          top: 'auto',
          bottom: 0,
          width: '100%',
          height: 64,
          backgroundColor: 'transparent',
          zIndex: 1200,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: baseTheme.palette.text.secondary,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,0.9)',
          }}
        >
          {text}
          <Box sx={{position: 'relative'}}>
            <Button
              variant="contained"
              disabled={loading}
              onClick={handleClick}
            >
              同意する
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
        </Box>
      </Card>
    </Slide>
  );
}
