import useSWRImmutable from "swr/immutable";

/**
 * 動画のアポ打診ポップアップ表示フラグを保持するカスタムフック
 */
export const useVideoAppointmentPopup = () => {
  const {data, mutate} = useSWRImmutable<boolean>('isShowVideoAppointmentPopup', null);

  const setShow = () => mutate(true);

  return {
    isShow: Boolean(data),
    setShow,
  }
}
