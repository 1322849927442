import {DateTime, Interval} from "luxon";

const _timestamps = {
  local: DateTime.now(),
  remote: DateTime.now(),
  page: DateTime.now(),
}

export const timestamps = {
  initialized: false,
  initialize: (remoteDateIso: string) => {
    _timestamps.local = DateTime.now();
    _timestamps.remote = DateTime.fromISO(remoteDateIso);
    timestamps.initialized = true;
  },
  recordPageStart: () => {
    const duration = timestamps.getPageDuration();
    _timestamps.page = DateTime.now();
    return duration;
  },
  getRemoteNow: () => _timestamps.remote.plus(Interval.fromDateTimes(_timestamps.local, DateTime.now()).length()),
  getPageDuration: () => Interval.fromDateTimes(_timestamps.page, DateTime.now()).length('seconds'),
}
