import React, {ChangeEvent, SyntheticEvent, useState} from "react";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent, DialogContentText,
  DialogTitle, FormControl,
  FormControlLabel,
  FormHelperText
} from "@mui/material";
import {baseTheme} from "../themes/baseTheme";
import {ProgressButton} from "./ProgressButton";
import {api} from "../libs/api";
import {AxiosError} from "axios";
import {useViewerSession} from "../hooks/useViewerSession";
import {LoginError, LoginFailureResponse, LoginSuccessResponse} from "../types/api";
import {LoginTextField} from "./LoginTextField";
import {LoginParameter} from "../types/auth";

type Props = {
  token: string
  title: string|null
  message: string|null
  needParameters?: LoginParameter[]
  policyUrl?: string|null
};
/**
 * ログインフォーム
 * @constructor
 */
export const LoginForm: React.FC<Props> = (
  {token, title, message, needParameters, policyUrl}
) => {
  const [open] = useState(true);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [acceptedPolicy, setAcceptedPolicy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<LoginError|undefined>(undefined);
  const {setSessionId} = useViewerSession(token);

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);
  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);
  const handleChangeAcceptedPolicy = (e: SyntheticEvent, checked: boolean) => setAcceptedPolicy(checked);

  const handleClickOk = () => {
    setLoading(true);
    api.post<LoginSuccessResponse>(
      `/login`,
      {token, password, email, accept_policy: acceptedPolicy}
    )
      .then((res) => setSessionId(res.data.session_id))
      .catch((e: AxiosError<LoginFailureResponse>) => {
        if (e.isAxiosError && e.response?.status === 422) {
          setError(e.response.data.errors);
        }
      })
      .finally(() => setLoading(false));
  }

  //プライバシーポリシーのエラー
  const acceptPolicyError = (() => {
    if (! error) return undefined;
    if (! error.accept_policy || error.accept_policy.length <= 0) return undefined;
    return error.accept_policy[0];
  })();

  return (
    <>
      <Dialog open={open}>
        <DialogTitle
          color={baseTheme.palette.text.secondary}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            v-if={message}
            whiteSpace="pre-wrap"
            fontSize="small"
          >
            {message}
          </DialogContentText>
          {/* メールアドレス入力欄 */}
          {needParameters?.includes('email') && <LoginTextField
            autoFocus
            label="メールアドレス"
            value={email}
            name="email"
            onChange={handleChangeEmail}
            errorObj={error}
          />}
          {/* パスワード入力欄 */}
          {needParameters?.includes('password') && <LoginTextField
            autoFocus
            label="パスワード"
            type="password"
            value={password}
            name="password"
            onChange={handleChangePassword}
            errorObj={error}
          />}
          {/* プライバシーポリシー同意 */}
          {needParameters?.includes('accept_policy') && (
            <FormControl error={!! acceptPolicyError}>
              <FormControlLabel
                value={acceptedPolicy}
                onChange={handleChangeAcceptedPolicy}
                control={<Checkbox />}
                label={(
                  <Box>
                    <a href={policyUrl ?? ''} target="_blank" rel={"noreferrer"}>プライバシーポリシー</a>に同意の上、OKボタンをクリックしてください。
                  </Box>
                )}
                sx={{
                  '& > .MuiFormControlLabel-label' : {
                    color: baseTheme.palette.text.secondary,
                    fontSize: '.8em',
                  },
                }}
              />
              {acceptPolicyError && <FormHelperText>{acceptPolicyError}</FormHelperText>}
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <ProgressButton
            variant="contained"
            loading={loading}
            onClick={handleClickOk}
          >
            OK
          </ProgressButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
