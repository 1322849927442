import React from "react";
import {DocumentInfo} from "../../types/document";
import {AppBar, IconButton, Toolbar, Typography} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useCurrentPage} from "../../hooks/useCurrentPage";

type Props = {
  info: DocumentInfo
};

export const PdfViewerFooter: React.FC<Props> = ({info}) => {
  const {page, changePage} = useCurrentPage(info);

  //前のページに戻る
  const handleClickBack = () => {
    if (page > 1) {
      changePage(page - 1);
    }
  }
  //次のページに進む
  const handleClickForward = () => {
    if (page < info.content.page_count) {
      changePage(page + 1);
    }
  }

  return (
    <AppBar
      component="footer"
      position="fixed"
      color="transparent"
      elevation={2}
      sx={{
        top: 'auto',
        bottom: 0,
        borderTop: '1px solid white',
      }}
    >
      <Toolbar
        sx={{
          justifyContent: 'center',
        }}
      >
        <IconButton
          color="inherit"
          disabled={page === 1}
          onClick={handleClickBack}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography>
          {page} / {info.content.page_count}
        </Typography>
        <IconButton
          color="inherit"
          disabled={page === info.content.page_count}
          onClick={handleClickForward}
        >
          <ArrowForwardIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
