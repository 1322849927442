import axios from "axios";

export const queue = axios.create({
  baseURL: process.env.REACT_APP_SQS_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'x-api-key': process.env.REACT_APP_SQS_X_API_KEY ?? '',
  },
});
