import {DocumentInfo} from "../../types/document";
import React from "react";
import {VideoViewerHeader} from "./VideoViewerHeader";
import {VideoViewerPlayer} from "./VideoViewerPlayer";
import {VideoViewerAppointmentPopup} from "./VideoViewerAppointmentPopup";

/**
 * 動画ビューア
 * @param info
 * @constructor
 */
export const VideoViewer: React.FC<{info: DocumentInfo}> = ({info}) => {
  return (
    <>
      {/* ヘッダー */}
      <VideoViewerHeader info={info} />
      {/* 動画表示部 */}
      <VideoViewerPlayer info={info} />
      {/* アポ打診ポップアップ */}
      <VideoViewerAppointmentPopup info={info} />
    </>
  )
}
