import React, {useState} from "react";
import {DocumentInfo} from "../../types/document";
import {useCurrentPage} from "../../hooks/useCurrentPage";
import {AppointmentPopup} from "../AppointmentPopup/AppointmentPopup";

/**
 * アポ打診ポップアップ表示判定
 * @param info
 * @param page
 */
const isShowAppointmentPopup = (info: DocumentInfo, page: number) => {
  if (info.appointment_popup === undefined) return false;

  return page >= info.appointment_popup.page;
};

/**
 * PDFビューア内のアポ打診ポップアップ表示制御
 * @param info
 * @constructor
 */
export const PdfViewerAppointmentPopup: React.FC<{info: DocumentInfo}> = ({info}) => {
  const [open, setOpen] = useState(false);
  const [opened, setOpened] = useState(false);
  const {page} = useCurrentPage(info);

  //アポ打診ポップアップ表示判定
  if (! opened && isShowAppointmentPopup(info, page)) {
    setOpen(true);
    setOpened(true);
  }

  const handleClosePopup = () => setOpen(false);

  return info.appointment_popup ? (
      <AppointmentPopup info={info} open={open} onClose={handleClosePopup} />
  ) : <></>;
}
