import React from "react";
import {Box, CircularProgress, Stack, Typography} from "@mui/material";

/**
 * 読み込み中アニメーション
 * @constructor
 */
export const LoadingAnimation: React.FC = () => {
  return (
    <Stack spacing={2} textAlign="center" mt={20}>
      <Box>
        <CircularProgress size={100} />
      </Box>
      <Typography>読込中...</Typography>
    </Stack>
  )
}
