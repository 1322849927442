import React from "react";
import {DocumentInfo} from "../../types/document";
import {IconButton} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {api} from "../../libs/api";
import {isPreview} from "../../libs/isPreview";

/**
 * ダウンロードボタン
 * @param info
 * @constructor
 */
export const PdfViewerDownload: React.FC<{info: DocumentInfo}> = ({info}) => {
  if (! info.content.raw_file) return <></>;

  const handleClick = () => {
    if (! isPreview()) {
      //ダウンロードクリックイベント発火
      api.post(`/sessions/${info.session}/download_click`).then();
    }
    //ダウンロード実行
    window.open(info.content.raw_file, '_blank');
  }

  return (
    <IconButton
      size="large"
      color="inherit"
      onClick={handleClick}
    >
      <FileDownloadIcon />
    </IconButton>
  )
}
