import {createTheme} from "@mui/material";

export const baseTheme = createTheme({
  palette: {
    background: {
      default: '#222d32',
    },
    primary: {
      main: '#3fa097',
    },
    text: {
      primary: '#ffffff',
    }
  },
});
