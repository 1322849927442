import {DocumentInfo} from "../types/document";
import {sendAccessLog} from "../libs/sendAccessLog";
import {useViewerSession} from "./useViewerSession";
import {timestamps} from "../libs/timestamps";
import useSWRImmutable from "swr/immutable";

/**
 * 現在のページを保持するカスタムフック
 * @param info
 */
export const useCurrentPage = (info: DocumentInfo) => {
  const {setSessionId} = useViewerSession(info.token);
  //SWRを用いて現在表示中のページを保持
  const {data, mutate} = useSWRImmutable<number>('currentPage', null);

  const page = data || 1;
  const setPage = (next: number) => mutate(next);

  //ページ移動
  const changePage = (next: number) => {
    const duration = timestamps.recordPageStart();
    if (info.session) {
      //移動前のページの記録
      sendAccessLog(info, page, duration).then();
      //移動後のページの記録
      sendAccessLog(info, next, 0).then();
      //セッションの有効期限を延ばす
      setSessionId(info.session);
    }
    setPage(next).then();
  }

  return {page, changePage}
}
