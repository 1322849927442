import {queue} from "./queue";
import {isPreview} from "./isPreview";
import {timestamps} from "./timestamps";
import {DocumentInfo} from "../types/document";

export const sendAccessLog = async (
  info: DocumentInfo,
  page: number,
  duration: number
) => {
  if (! isPreview()) {
    await queue.post('', {
      token: info.token,
      session_id: info.session,
      duration,
      page,
      start: timestamps.getRemoteNow().setZone('UTC').toISO(),
    })
  }
}
