import React, {SyntheticEvent} from "react";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Slide,
  SlideProps,
  Snackbar,
  SnackbarProps,
  Typography
} from "@mui/material";
import {AppointmentPopupConfig} from "../../types/document";
import {baseTheme} from "../../themes/baseTheme";
import CloseIcon from "@mui/icons-material/Close";

type Props = SnackbarProps & {
  appointmentPopup: AppointmentPopupConfig
}

const SlideTransition = (props: SlideProps) => {
  return <Slide {...props} direction="up"  children={props.children} />;
}

/**
 * サンクスポップアップ
 * @param appointmentPopup
 * @param props
 * @constructor
 */
export const AppointmentPopupThanks: React.FC<Props> = (
  {appointmentPopup, ...props}
) => {
  const handleClickClose = (e: SyntheticEvent) => (props.onClose && props.onClose(e, 'clickaway'));

  const thanksText = appointmentPopup.thanks_text
    ?? 'ありがとうございます。後日担当者よりご連絡致します。';

  return (
    <Snackbar
      {...props}
      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      TransitionComponent={SlideTransition}
      autoHideDuration={5000}
    >
      <Card
        sx={{
          maxWidth: 460,
          color: baseTheme.palette.text.secondary,
          backgroundColor: '#ECF0F6',
        }}
      >
        <CardHeader
          action={
            <IconButton
              color="primary"
              onClick={handleClickClose}
            >
              <CloseIcon />
            </IconButton>
          }
          sx={{
            paddingBottom: 0,
          }}
        />
        <CardContent>
          <Typography fontWeight="bold">{thanksText}</Typography>
        </CardContent>
      </Card>
    </Snackbar>
  )
}
