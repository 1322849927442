import React from "react";
import {DocumentInfo} from "../../types/document";
import {PdfViewerHeader} from "./PdfViewerHeader";
import {sendAccessLog} from "../../libs/sendAccessLog";
import {PdfViewerFooter} from "./PdfViewerFooter";
import {PdfViewerPageImage} from "./PdfViewerPageImage";
import {PdfViewerAppointmentPopup} from "./PdfViewerAppointmentPopup";
import {timestamps} from "../../libs/timestamps";

//初回ログ送信は必ず1度だけ行う
let sendInitialLog = false;

export const PdfViewer: React.FC<{info: DocumentInfo}> = ({info}) => {
  //初回のログ送信
  if (! sendInitialLog && info.session && timestamps.initialized) {
    sendAccessLog(info, 1, 0).then();
    sendInitialLog = true;
  }

  return (
    <>
      {/* ヘッダー */}
      <PdfViewerHeader info={info} />
      {/* ページ表示 */}
      <PdfViewerPageImage info={info} />
      {/* フッター */}
      <PdfViewerFooter info={info} />
      {/* アポ打診ポップアップ */}
      <PdfViewerAppointmentPopup info={info} />
    </>
  );
}
