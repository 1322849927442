import React from "react";
import {TextField, TextFieldProps} from "@mui/material";
import {baseTheme} from "../themes/baseTheme";
import {LoginError} from "../types/api";
import {LoginParameter} from "../types/auth";

type Props = TextFieldProps & {
  name: LoginParameter,
  errorObj?: LoginError,
}

/**
 * ログインフォームのテキストフィールド
 * @param errorObj
 * @param props
 * @constructor
 */
export const LoginTextField: React.FC<Props> = ({errorObj, ...props}) => {
  const isError = (errorObj !== undefined) && (errorObj[props.name] !== undefined);
  const helperText = (() => {
    if (! errorObj) return undefined;
    const errors = errorObj[props.name];
    if (! errors) return undefined;
    if (errors.length <= 0) return undefined;
    return errors[0];
  })();

  return (
    <TextField
      fullWidth
      margin="dense"
      variant="standard"
      sx={{
        '& .MuiInput-root': {
          color: baseTheme.palette.text.secondary,
        },
      }}
      error={isError}
      helperText={helperText}
      required
      {...props}
    />
  )
}
