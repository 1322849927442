import axios from "axios";

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_APP_URL}/viewer-api`,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
  },
});

export const isAxiosError = axios.isAxiosError
