import React from "react";
import {Box, Button, ButtonProps, CircularProgress} from "@mui/material";

type Props = ButtonProps & {
  loading: boolean
}

export const ProgressButton: React.FC<Props> = ({
  loading,
  ...props
}) => {
  return (
    <Box textAlign="center" sx={{position: 'relative'}}>
      <Button
        {...props}
        disabled={loading}
      />
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </Box>
  )
}
