import React from "react";
import WarningIcon from "@mui/icons-material/Warning";
import {Stack, Typography, useMediaQuery} from "@mui/material";
import {baseTheme} from "../themes/baseTheme";

export const NotFound: React.FC = () => {
  const isSmallDisplay = useMediaQuery(baseTheme.breakpoints.down('sm'));

  return (
    <>
      <Stack
        alignItems="center"
        justifyContent="center"
        mt={20}
        spacing={2}
      >
        <WarningIcon
          sx={{ fontSize: 100 }}
          color="warning"
        />
        <Typography variant={isSmallDisplay ? 'h5' : 'h3'} textAlign="center">資料が見つかりませんでした。</Typography>
      </Stack>
      <Typography variant="subtitle1" textAlign="center">URLをよくお確かめください。</Typography>
    </>
  );
}
