import React, {SyntheticEvent} from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader, CircularProgress,
  IconButton,
  Slide,
  SlideProps,
  Snackbar,
  SnackbarProps, Stack,
  Typography
} from "@mui/material";
import {baseTheme} from "../../themes/baseTheme";
import CloseIcon from "@mui/icons-material/Close";
import {AppointmentPopupConfig} from "../../types/document";

type Props = SnackbarProps & {
  appointmentPopup: AppointmentPopupConfig
  onClickButton?: () => any
  loading?: boolean
}

const SlideTransition = (props: SlideProps) => {
  return <Slide {...props} direction="up"  children={props.children} />;
}

/**
 * アポ打診ポップアップ
 * @param appointmentPopup
 * @param onClickButton
 * @param loading
 * @param props
 * @constructor
 */
export const AppointmentPopupFirst: React.FC<Props> = (
  {
    appointmentPopup,
    onClickButton,
    loading,
    ...props
  }
) => {
  const handleClickClose = (e: SyntheticEvent) => (props.onClose && props.onClose(e, 'clickaway'));

  const appointText = appointmentPopup.appoint_text
    ?? '資料のご紹介のお時間をいただけませんか？担当営業から日程候補日をお送りします。';
  const buttonText = appointmentPopup.appoint_button
    ?? '連絡を希望する';
  const iconPath = appointmentPopup.icon
    ?? '/customer_service.jpg';

  const handleClickButton = () => (onClickButton && onClickButton());

  return (
    <Snackbar
      {...props}
      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      TransitionComponent={SlideTransition}
      ClickAwayListenerProps={{
        mouseEvent: false,
        touchEvent: false,
      }}
    >
      <Card
        sx={{
          maxWidth: 460,
          color: baseTheme.palette.text.secondary,
          backgroundColor: '#ECF0F6',
        }}
      >
        <CardHeader
          action={
            <IconButton
              color="primary"
              onClick={handleClickClose}
            >
              <CloseIcon />
            </IconButton>
          }
          sx={{
            paddingBottom: 0,
          }}
        />
        <CardContent>
          <Stack direction="row" spacing={2}>
            <Avatar
              src={iconPath}
              sx={{width: 80, height: 80, border: '2px solid white'}}
            />
            <Box>
              <Typography fontWeight="bold">{appointText}</Typography>
              <Box textAlign="center" mt={2} sx={{position: 'relative'}}>
                <Button
                  variant="contained"
                  sx={{fontWeight: 'bold'}}
                  onClick={handleClickButton}
                  disabled={loading}
                >
                  {buttonText}
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Box>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Snackbar>
  )
}
