import React from "react";
import {DocumentInfo} from "../types/document";
import {Helmet, HelmetProvider} from "react-helmet-async";

export const DocumentTitle: React.FC<{info: DocumentInfo}> = ({info}) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{info.content.name}</title>
      </Helmet>
    </HelmetProvider>
  )
}
