import React, {useState} from "react";
import {DocumentInfo} from "../../types/document";
import {isPreview} from "../../libs/isPreview";
import {api} from "../../libs/api";
import {AppointmentPopupFirst} from "./AppointmentPopupFirst";
import {AppointmentPopupThanks} from "./AppointmentPopupThanks";

export type Props = {
  info: DocumentInfo
  open: boolean
  onClose?: () => any
}

/**
 * アポ打診ポップアップ
 * @constructor
 */
export const AppointmentPopup: React.FC<Props> = ({info, open, onClose}) => {
  const [openThanks, setOpenThanks] = useState(false);
  const [loading, setLoading] = useState(false);

  //クローズハンドラ
  const handleClosePopup = () => (onClose && onClose());
  const handleCloseThanks = () => setOpenThanks(false);

  //アポイントボタンクリックハンドラ
  const handleClickButton = async () => {
    setLoading(true);
    // クリック情報を送信
    isPreview() || await api.post(`/sessions/${info.session}/appointment_click`);
    if (info.appointment_popup?.eeasy) {
      //外部リンクタイプの場合
      window.open(info.appointment_popup.eeasy, '_blank');
    } else {
      isPreview() || await api.post(`/sessions/${info.session}/send_mail_to_client`, {token: info.token});
      //通知送信タイプの場合
      setOpenThanks(true);
    }
    onClose && onClose();
    setLoading(false);
  }
  return info.appointment_popup ? (
    <>
      <AppointmentPopupFirst
        appointmentPopup={info.appointment_popup}
        open={open}
        onClose={handleClosePopup}
        onClickButton={handleClickButton}
        loading={loading}
      />
      <AppointmentPopupThanks
        appointmentPopup={info.appointment_popup}
        open={openThanks}
        onClose={handleCloseThanks}
      />
    </>
  ) : <></>;
}
