import {useCookies} from "react-cookie";

/**
 * 閲覧セッションを管理するカスタムフック
 * @param token
 */
export const useViewerSession = (token: string) => {
  const cookieName = `s_id-${token}`;
  const [cookies, setCookie] = useCookies([cookieName]);

  const setSessionId = (sessionId: number) => setCookie(cookieName, sessionId, {maxAge: 600});

  const sessionId = cookies[cookieName] as string|undefined;

  return {
    sessionId,
    setSessionId,
  }
};
