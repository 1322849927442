import React, {SyntheticEvent, useCallback, useEffect, useRef, useState} from "react";
import {DocumentInfo, PdfLink} from "../../types/document";
import {Box, CircularProgress} from "@mui/material";
import {getImagePath} from "../../libs/getImagePath";
import {useCurrentPage} from "../../hooks/useCurrentPage";
import {useWindowSize} from "../../hooks/useWindowSize";
import {isPreview} from "../../libs/isPreview";
import {timestamps} from "../../libs/timestamps";

/**
 * ページ画像
 * @param info
 * @constructor
 */
export const PdfViewerPageImage: React.FC<{info: DocumentInfo}> = ({info}) => {
  const [loading, setLoading] = useState(true);
  const {page, changePage} = useCurrentPage(info);
  const imagePath = getImagePath(info, page);
  const el = useRef<HTMLImageElement>();
  const [rect, setRect] = useState({width: 0, height: 0});

  //ページが変わったらローディング中にする
  useEffect(() => {
    if (! el.current?.complete) {
      setLoading(true);
      setRect({width: 0, height: 0});
    }
  }, [page]);

  //画像読み込みが終わったらローディング中を終了する
  const handleEndLoadImage = () => setLoading(false);

  //画像サイズを取得する
  //ローディング終了時、画面サイズ変更時に実行される
  const [windowWidth, windowHeight] = useWindowSize();
  useEffect(() => {
    if (! loading && el.current?.offsetWidth) {
      setRect({width: el.current?.offsetWidth ?? 0, height: el.current?.offsetHeight ?? 0});
    }
  }, [loading, windowWidth, windowHeight]);

  //エリアクリック時の処理
  const handleClickArea = useCallback((link: PdfLink) => {
    if (link.type === 1) {
      //外部リンクタイプ
      window.open(link.dest, '_blank');
    } else {
      //ページ移動タイプ
      changePage(Number(link.dest));
    }
  }, [changePage]);

  //クリッカブルマップの生成
  const createMap = () => {
    const currentPdfPage = info.pages.find(pdfPage => pdfPage.page_number === page);
    if (! currentPdfPage) return;
    const rate = rect.width / currentPdfPage.width;

    return (currentPdfPage.links ?? []).map((link, i) => {
      //描画場所を計算
      const x1 = link.llx * rate;
      const y1 = (currentPdfPage.height - link.ury) * rate;
      const x2 = link.urx * rate;
      const y2 = (currentPdfPage.height - link.lly) * rate;

      //クリック時の処理
      const handleClick = () => handleClickArea(link);

      return (
        <Box
          component="area"
          key={`links-${page}-${i}`}
          alt="link"
          shape="rect"
          coords={`${x1},${y1},${x2},${y2}`}
          onClick={handleClick}
          sx={{
            cursor: 'pointer',
          }}
        />
      );
    });
  }

  const areas = rect.width > 0 ? createMap() : undefined;

  //画面アンロード時にログ送信（キューではなくAPIサーバーに送信する）
  useEffect(() => {
    const handler = () => {
      if (! isPreview()) {
        const formData = new FormData();
        formData.append('start', timestamps.getRemoteNow().setZone('UTC').toISO());
        formData.append('duration', String(timestamps.getPageDuration()));
        formData.append('page', String(page));
        const url = `${process.env.REACT_APP_BACKEND_APP_URL}/viewer-api/sessions/${info.session}/track_pdf_view`;
        navigator.sendBeacon(url, formData);
      }
    };
    window.addEventListener('unload', handler);

    return () => window.removeEventListener('unload', handler);
  }, [info.session, page]);

  //画面の余白部分をクリックした場合はページ移動
  const handleClickEmptyArea = (e: SyntheticEvent<HTMLElement, MouseEvent>) => {
    const {target} = e;
    if (!(target instanceof HTMLDivElement)) {
      return;
    }
    if (target.classList.contains('pageMoveTarget')) {
      if (e.nativeEvent.clientX < windowWidth / 2) {
        page > 1 && changePage(page - 1);
      } else {
        page < info.content.page_count && changePage(page + 1);
      }
    }
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        height: 'calc(100vh - 130px)',
        cursor: 'pointer',
      }}
      position="relative"
      onClick={handleClickEmptyArea}
      className="pageMoveTarget"
    >
      <Box
        textAlign="center"
        position="relative"
        sx={{
          cursor: 'auto',
        }}
      >
        <Box
          component="img"
          src={imagePath}
          sx={{
            display: loading ? 'none' : 'block',
            maxWidth: '100%',
            maxHeight: 'calc(100vh - 130px)',
            userSelect: 'none',
          }}
          onLoad={handleEndLoadImage}
          useMap="#links"
          ref={el}
        />
        {loading && <CircularProgress />}
        <map name="links">
          {areas}
        </map>
      </Box>
    </Box>
  );
}
