import useSWRImmutable from 'swr/immutable'
import {DocumentResponse} from "../types/document";
import {fetcher} from "../libs/fetcher";
import {AxiosError} from "axios";
import {useViewerSession} from "./useViewerSession";
import {isPreview} from "../libs/isPreview";
import {UnauthorizedResponse} from "../types/api";
import {timestamps} from "../libs/timestamps";
import {LoginParameter} from "../types/auth";

// 再取得制御用のシングルトンなキャッシュ
const cache: {
  params?: URLSearchParams
} = {};

/**
 * URL情報取得用カスタムフック
 * @param token
 */
export const useDocumentInfo = (token: string) => {
  const {sessionId, setSessionId} = useViewerSession(token);

  const params = new URLSearchParams();
  params.append('token', token);
  sessionId && params.append('session', sessionId);
  isPreview() && params.append('preview', '');

  const {data, error} = useSWRImmutable<DocumentResponse<LoginParameter>, AxiosError<UnauthorizedResponse|any>>(
    `/info?${(cache.params ? cache.params : params).toString()}`,
    fetcher,
    {
      shouldRetryOnError: false,
    },
  );

  //セッションIDを保存
  if (! isPreview() && data && data.is_viewable && data.document.session) {
    setSessionId(data.document.session);
  }

  //一度取得した後は自動再取得させない
  if (cache.params === undefined && data !== undefined && error === undefined && data.is_viewable) {
    cache.params = params;

    //取得成功時に時刻を記録
    timestamps.initialize(data.document.remote_date);
  }

  const info = (data && data.is_viewable) ? data.document : undefined;
  const isUnauthorized = (data && ! data.is_viewable);
  const loginFormTitle = (data && ! data.is_viewable) ? data.error.title : '';
  const loginFormMessage = (data && ! data.is_viewable) ? data.error.message : '';
  const needParameters = (data && ! data.is_viewable) ? data.error.needs_parameters : undefined;
  const policyUrl = (data && ! data.is_viewable) ? data.policy_url : undefined;

  return {
    info,
    loading: data === undefined && error === undefined,
    isNotFound: error?.response?.status === 404 || error?.response?.status === 422,
    isUnauthorized,
    loginFormTitle,
    loginFormMessage,
    needParameters,
    policyUrl,
  }
}
